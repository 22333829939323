<template>
    <v-container fluid >
        <v-layout align-center justify-center>
            <v-card>
                <v-img
                    class="white--text align-top"
                    height="200px"
                    gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    src="/images/covid.jpg"
                >
                </v-img>
                
                <v-card-text>
                    
                    <v-toolbar short dense color="blue darken-3" dark flat>

                        <v-toolbar-title>Triage Respiratorio</v-toolbar-title>
                        
                        <v-progress-linear
                            v-if="loadingDatos"
                            :active="loadingDatos"
                            :indeterminate="loadingDatos"
                            absolute
                            bottom
                            height='5'
                            color="light-blue lighten-5"
                        ></v-progress-linear>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="cargaCovidTodos">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                
                        <v-btn icon @click="nuevoCovidDialog=true">
                            <v-icon>mdi-account-plus</v-icon>
                        </v-btn>
                        
                    </v-toolbar>

                    <v-simple-table>
                        <template v-slot:default>
                            
                            <thead>
                                <tr>
                                    <th class="blue lighten-3 text-center"><span class="body-2 font-weight-black">Fecha/Hora</span></th>
                                    <th class="blue lighten-3 text-center"><span class="body-2 font-weight-black">Paciente</span></th>
                                    <th class="blue lighten-3 text-center"><span class="body-2 font-weight-black">Rebibe</span></th>
                                    <th class="blue lighten-3 text-center"><span class="body-2 font-weight-black"></span></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr class="mb-3" v-for="item in listaAtenciones" :key="item.id">
                                    
                                    <td width="20%" class="textoarriba tablaDiag">
                                        {{item.ingreso}}
                                    </td>
                                    <td width="60%" class="textoarriba tablaDiag">
                                        ({{item.paciente}}) {{item.nombreCompleto}} - {{item.edad}}<br/>

                                    </td>
                                    <td width="15%" class="textoarriba tablaDiag">
                                        {{item.recibidoPor}}
                                    </td>
                                    <td width="15%" class="textoarriba tablaDiag">
                                        <v-icon @click="cargaDetalle(item.internamiento)">mdi-magnify</v-icon>
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    
                    <v-pagination
                        v-model="page"
                        :length="pageLength"
                        total-visible="7"
                        :disabled="loadingDatos"
                    ></v-pagination>
                    

                    <covid-agrega
                        :nuevoCovidDialog.sync="nuevoCovidDialog"
                        @registroGuardado="cargaCovidTodos"
                    />
                </v-card-text>
            </v-card>
        </v-layout>
    </v-container>
</template>

<script>
import NuevoCovid from '@/components/urgencias/AgregaCovid'
export default {
    mounted(){
        this.cargaCovidTodos()
    },
    components:{
        'covid-agrega':NuevoCovid,
    },
    data:()=>({
        
        loadingDatos:false,
        nuevoCovidDialog:false,
        listaAtenciones:[],

        // Para la paginación
        limit:20,
        page:1,
        total:0,
        // Para la paginación
    }),
    methods:{
        async cargaCovidTodos(){
            this.loadingDatos = true
            let params = {
                tipoInternamiento:4,

                page:this.page,
                start: (this.page -1) * this.limit,
                limit: this.limit
            }

            try{
                let la = await this.$http({
                    method:'GET',
                    url:'/urgencias/listAtenciones',
                    params:params,
                })
                this.loadingDatos = false
                this.listaAtenciones=la.data.root
                this.total = la.data.total

            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
            }
//            console.log('Carga COVID')
        },
        cargaDetalle(id){
            //console.log(id)
            this.$router.push({path:`/urgencias/detalleCovid/`+id})
        },
    },
    computed:{
        pageLength(){
            return (Math.ceil(this.total / this.limit));
        },
    },
    watch:{
        page(){
            //this.pageAc=1
            this.cargaCovidTodos()
        }
    },
}
</script>

<style>
.textoarriba{
    vertical-align: top;

}
.tablaDiag{
    border: 1px solid  #ECECEC;
    border-spacing: 0.5rem;
    border-collapse: collapse;
}
</style>