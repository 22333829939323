<template>
    <v-dialog
        v-model="dialog"
        max-width="500"
        persistent
    >
        <v-form
            ref="covidForm"
            @submit="agrega"
            onSubmit="return false;"
            v-model="validaCovid"
        >
            <v-card>
                <v-card-title
                    class="headline orange lighten-2"
                    primary-title
                >
                    Nuevo para valoración
                </v-card-title>
                    
                <v-card-text class="mt-3">
                    <v-row no-gutters>  
                        <v-col cols="6" class="pr-1">
                            <v-text-field
                                v-model="datosCovid.cip"
                                hint="Si ya es paciente"
                                persistent-hint
                                filled
                                rounded
                                dense
                                label="C.I.P."
                                type="number"
                                
                                >
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" class="pr-1">
                            <v-text-field
                                v-model="datosCovid.nombres"
                                :readonly="existePaciente"
                                :rules="requeridoRule"
                                filled
                                rounded
                                dense
                                label="Nombres"
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>  
                        <v-col cols="6" class="pr-1">
                            <v-text-field
                                v-model="datosCovid.primerApellido"
                                filled
                                rounded
                                dense
                                :readonly="existePaciente"
                                :rules="requeridoRule"
                                label="Primer apellido"
                                >
                            </v-text-field>
                        </v-col>
                        <v-col cols="6" class="pl-1">
                            <v-text-field
                                v-model="datosCovid.segundoApellido"
                                filled
                                rounded
                                dense
                                :readonly="existePaciente"
                                :rules="requeridoRule"
                                label="Segundo apellido"
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>
                        

                    <v-row no-gutters>  
                        <v-col cols="6">
                            <v-radio-group v-model="datosCovid.sexo" :readonly="existePaciente" row>
                                <v-radio
                                    label="Masc."
                                    value="m"
                                ></v-radio>
                                <v-radio
                                    label="Fem."
                                    value="f"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                hint="Formato DD/MM/AAAA"
                                persistent-hint
                                v-model="datosCovid.fechaNacimiento"
                                filled
                                :readonly="existePaciente"
                                :rules="requeridoRule"
                                rounded
                                dense
                                label="Nacimiento"
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>
                        
                    <v-text-field
                        v-model="datosCovid.responsable"
                        filled
                        rounded
                        :readonly="existePaciente"
                        :rules="requeridoRule"
                        dense
                        label="Responsable"
                        >
                    </v-text-field>

                    <v-text-field
                        v-model="datosCovid.domicilio"
                        filled
                        rounded
                        :readonly="existePaciente"
                        :rules="requeridoRule"
                        dense
                        label="Domicilio"
                    >
                    </v-text-field>

                    <v-text-field
                        v-model="datosCovid.telefono"
                        filled
                        rounded
                        :readonly="existePaciente"
                        dense
                        label="Teléfono de contacto"
                        >
                    </v-text-field>
                        
                        
                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn text color="error" @click="cancelaAgrega">
                        Cancelar
                    </v-btn>

                    <v-btn
                        text
                        color="success"
                        type="submit"
                        :disabled="!validaCovid"
                        :loading="loadingCovid"
                    >
                        Agregar
                    </v-btn>


                </v-card-actions>
            </v-card>
        </v-form>


        
    </v-dialog>
</template>

<script>
export default {
    props:{
        nuevoCovidDialog:Boolean
    },
    data:()=>({
        validaCovid:false,
        loadingCovid:false,

        requeridoRule: [
            v => !!v || 'Requerido',
        ],

        expedienteRule: [
            v => (v >= 10000002) || 'El número no es válido',
        ],

        existePaciente:false,
        datosCovid:{
            cip:0,
            nombres:'',
            primerApellido:'',
            segundoApellido:'',
            fechaNacimiento:'',
            sexo:'',
            domicilio:'',
            telefono:'',
            responsabe:'',
        },


    }),
    methods:{
        async agrega(){
            
            this.loadingCovid = true
            
            if(this.existePaciente){
                
                let params = {

                    cip: this.datosCovid.cip,
                    presentaLesion: false,
                    referidoPor: 8,
                    trasladoTransitorio: false,
                    atencionPrehospitalaria: false,
                    motivo: 'TRIAGE RESPIRATORIO',
                    cama: 'Triage RESP',
                    tipoInternamiento: 4
                }

                try {
                    let int = await this.$http({
                        method:'GET',
                        url:'/urgencias/createV2',
                        params:params
                    })
                    //console.log(int.data)
                    if (int.data.estado == 'true'){
                        this.loadingCovid = false
                        this.dialog=false
                        this.$emit('registroGuardado')
                        this.$refs.covidForm.reset()

                        // EGRESA AL PACIENTE
                        //console.log(int.data.root)
                        let params2 = {
                            motivoEgresoUrgencias: 4,
                            observacionesAlta: 'EGRESO DE CONSULTA COVID',
                            id: int.data.root.atencionUrgencias[0].id,
                            internamiento:int.data.root.id
                        }
                        this.$http({
                            method:'GET',
                            url:'/urgencias/egresaUrgencias',
                            params:params2
                        })




                    } else {
                        console.log(int.data.root)
                        this.$swal.fire({
                            icon: 'warning',
                            title: 'No se puede guardar REGISTRO DE ATENCIÓN',
                            text: 'Verifique los datos e intente de nuevo',
                        })
                    }
                }catch(err){
                    console.log(err)
                    this.loadingCovid = false
                    this.$store.dispatch('security/revisaError',err)
                }
                this.existePaciente=false
            } else {
                // Agrega paciene y luego agrega internamiento
                let params = {
    
                    nombres: this.datosCovid.nombres,
                    apellidoPaterno:this.datosCovid.primerApellido,
                    apellidoMaterno:this.datosCovid.segundoApellido,
                    fechaNacimiento:this.datosCovid.fechaNacimiento,
                    sexo:this.datosCovid.sexo,
                    vialidad:this.datosCovid.domicilio,
                    responsable:this.datosCovid.telefono,
                    domicilioTelefono:this.datosCovid.responsabe,
                    
                    observacionesNuevas: 'PARA COVID. COMPLETAR DATOS​',
                    
                    curp:'XXXX999999XXXXXX99',
                    estadoCivil: 99,
                    derechohabiencia: 11,
                    gratitud: 0,
                    statusRegistro: 1,
                    noExterior: 0,
                    estado: 20,
                    municipio: 200067,
                    localidad: 200670001,
                    codigoPostal: 200670001,
                    colonia: 'Oaxaca (Colonia)',
                    parentescoResponsable: 501,
                    etnico: 0,
                    espaniol: 0,
                    demograficosOrigenEtnico: 9900,
                    demiograficosReligion: 9900,
                    leerEscribir: 0,
                    escolaridadCompleta: 0,
                    escolaridad: 98,
                }
    
                try{
    
                    let pac = await this.$http({
                        method:'GET',
                        url:'/pacientes/createV2',
                        params:params
                    })

                    if (pac.data.estado == 'true'){

                        //console.log(pac.data.pacienteId)

                        let params2 = {

                            cip: pac.data.root.pacienteId,
                            presentaLesion: false,
                            referidoPor: 8,
                            trasladoTransitorio: false,
                            atencionPrehospitalaria: false,
                            motivo: 'TRIAGE RESPIRATORIO',
                            cama: 'Triage RESP',
                            tipoInternamiento: 4
                        }


                        let int = await this.$http({
                            method:'GET',
                            url:'/urgencias/createV2',
                            params:params2
                        })

                    //console.log(int.data)
                        if (int.data.estado == 'true'){
                            this.loadingCovid = false
                            this.dialog=false
                            this.$emit('registroGuardado')
                            this.$refs.covidForm.reset()

                            // EGRESA AL PACIENTE
                            //console.log(int.data.root)
                            let params3 = {
                                motivoEgresoUrgencias: 4,
                                observacionesAlta: 'EGRESO DE CONSULTA COVID',
                                id: int.data.root.atencionUrgencias[0].id,
                                internamiento:int.data.root.id
                            }
                            this.$http({
                                method:'GET',
                                url:'/urgencias/egresaUrgencias',
                                params:params3
                            })


                        }


                        } else {
                            this.loadingCovid = false
                            console.log(pac.data.root)
                            this.$swal.fire({
                                icon: 'warning',
                                title: 'No se puede guardar PACIENTE',
                                text: 'Verifique los datos e intente de nuevo',
                            })
                        }
    
                } catch(err){
                    console.log(err)
                    this.loadingCovid = false
                    this.$store.dispatch('security/revisaError',err)
                    
                }
                this.existePaciente=false
            }

        },




        async buscaPaciente(val){
            // datosCovid
            try{
                let pac = await this.$http({
                    url:'/pacientes/listV2',
                    method:'GET',
                    params:{id:val}
                })
                //console.log(pac.data.pacienteId)
                if (pac.data.pacienteId){
                    //console.log('SI')

                    this.existePaciente = true

                    this.datosCovid.nombres=pac.data.nombres
                    this.datosCovid.primerApellido=pac.data.apellidoPaterno
                    this.datosCovid.segundoApellido=pac.data.apellidoMaterno
                    this.datosCovid.fechaNacimiento=pac.data.fechaNacimiento
                    this.datosCovid.sexo=pac.data.sexo
                    this.datosCovid.domicilio=pac.data.localidadNom
                    this.datosCovid.telefono=pac.data.telefonoContacto
                    this.datosCovid.responsable=pac.data.responsable



                } else {
                    //console.log('NO')
                    this.existePaciente = false

                    this.datosCovid.nombres=''
                    this.datosCovid.primerApellido=''
                    this.datosCovid.segundoApellido=''
                    this.datosCovid.fechaNacimiento=''
                    this.datosCovid.sexo=''
                    this.datosCovid.domicilio=''
                    this.datosCovid.telefono=''
                    this.datosCovid.responsabe=''
                }
            }catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loadingSigno = false
            }
        },
        cancelaAgrega(){
            this.dialog=false
            this.$refs.covidForm.reset()
            this.existePaciente=false
        },
    },
    computed:{
        dialog:{
            get(){
                return this.nuevoCovidDialog
            },
            set(value){
                this.$emit('update:nuevoCovidDialog',value)
            },
        }
    },
    watch:{
        'datosCovid.cip'(val){
            if (val > 10000000)
                this.buscaPaciente(val)
        }
    }
}
</script>

<style>

</style>